/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { Layout, CategoryIcons, ArticleContent, Count } from 'components';
import { graphql, Link } from 'gatsby';
import UserIcon from 'assets/svg/usericon.inline.svg';
import ChatIcon from 'assets/svg/chat.inline.svg';
import RightIcon from 'assets/svg/rightarrow.inline.svg';
import { normalizePath } from 'utils/get-url-path';
import { truncate } from 'utils';
import { CategoryQuery } from 'queries/categories';
import Img from 'gatsby-image';
import moment from 'moment';

const CategoryPage: FC = ({ data, pageContext }: any) => {
  const pagedata = data.AllCategoryPage.edges;
  const categories = CategoryQuery();
  const categoryList = categories.allWpCategory.edges;
  const limit = 10;
  return (
    <Layout>
      <div className="pill-tags">
        <ul className="pill-tags-list">
          {categoryList?.map((tags: any, index: number) => {
            if (tags.node.count > 0) {
              return index < limit ? (
                <Link
                  to={normalizePath(tags.node.uri)}
                  className="pill-tags-links"
                >
                  <li>{tags.node.name}</li>
                </Link>
              ) : null;
            }
            return null;
          })}
        </ul>
      </div>

      {pagedata.map((item: any) => (
        <div className="main-page" key={item.node.name}>
          <h1 className="page-title">{item.node.name}</h1>
          <p className="page-description">{item.node.description}</p>
          <div className="articles-wrapper">
            {pagedata[0].node.posts.nodes.map((post: any) => {
              <CategoryIcons IconsData={post} />;
              const { content } = post;
              const trimcontent = truncate(content, 250);
              const articletitle = post.title;
              const trimtitle = truncate(articletitle, 45);
              return (
                <div className="articles-item" key={post.title}>
                  <div className="articles-image">
                    {post?.featuredImage && (
                      <Img
                        fluid={
                          post.featuredImage.node.localFile
                            ?.childrenImageSharp[0].fluid
                        }
                      />
                    )}
                  </div>
                  <div className="articles-content">
                    <div className="articles-content-item-description">
                      <div className="articles-content-button">
                        <Link to={normalizePath(post.uri)}>
                          <h5 className="articles-title">{trimtitle}</h5>
                        </Link>
                        <div className="articles-details">
                          <span>
                            <UserIcon /> {post.author.node.name}
                          </span>
                          <span className="articles-list-items">
                            {moment(post.date).format('MMMM D, YYYY')}
                          </span>
                          <span className="articles-list-items">
                            <Count articleUrl={post.uri} />
                          </span>
                        </div>
                        {!!post.content && (
                          <ArticleContent ArticleSummary={trimcontent} />
                        )}
                      </div>
                      <div className="articles-category">
                        <Link to={normalizePath(post.uri)}>
                          <span className="articles-link">Read More</span>{' '}
                          <RightIcon />
                        </Link>
                        <div className="articles-category-value">
                          <span>{post.categories.nodes[0].name}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </Layout>
  );
};

export default CategoryPage;

export const query = graphql`
  query ($name: String) {
    AllCategoryPage: allWpCategory(
      filter: {
        posts: {
          nodes: {
            elemMatch: {
              categories: { nodes: { elemMatch: { name: { eq: $name } } } }
            }
          }
        }
      }
    ) {
      edges {
        node {
          name
          slug
          description
          posts {
            nodes {
              categories {
                nodes {
                  name
                }
              }
              date
              commentCount
              title
              content
              uri
              author {
                node {
                  name
                }
              }
              featuredImage {
                node {
                  localFile {
                    childrenImageSharp {
                      fluid(maxWidth: 340, maxHeight: 180) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
